import React, { useState, useEffect } from 'react';
import { UserProfile } from '../../models/profile';
import SignIn from '../SignIn/SignIn';
import { useAuth } from '../../middleware/AuthContext';
import api from '../../services/api';
import Format1 from './Formats/Format1';

const Resume: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(!isAuthenticated); // Open sign-in modal if not authenticated
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();

  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data: userProfile } = await api.get('/user/getuser');
        setUserProfile(userProfile);
      } catch (error) {
        console.error('Failed to fetch user profile', error);
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return isModalOpen ? (
      <SignIn onClose={() => setIsModalOpen(false)} />
    ) : null;
  }

  if (!userProfile) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Format1 userProfile={userProfile} />
    </div>
  );
};

export default Resume;
