import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import api from '../../services/api';
import {
  UserProfile,
  WorkExperience,
  Education,
  Certification,
  Skill,
} from '../../models/profile';
import { MinusCircleIcon } from '@heroicons/react/24/outline';

const Profile: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [formData, setFormData] = useState<UserProfile>({
    email: '',
    name: '',
    summary: '',
    linkedin: '',
    phone: '',
    street_address: '',
    city: '',
    state: '',
    zip: '',
    work_experience: [],
    education: [],
    skills: [],
    certifications: [],
    last_login: '',
    created_at: '',
    updated_at: '',
  });

  const descriptionRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [generating, setGenerating] = useState<boolean>(false);

  const generateBulletPoints = async (index: number) => {
    if (!formData?.work_experience?.[index].description) {
      alert('Description is required to generate bullet points.');
      return;
    }

    setGenerating(true);
    try {
      const response = await api.post('openai/generateBulletPoints', {
        text: formData.work_experience[index].description,
      });

      // Clean bullet points: remove leading dashes, ** markers, and filter empty lines
      const bulletPoints: string[] = response.data.bulletPoints
        ?.map(
          (point: string) =>
            point.replace(/^-\s*/, '').replace(/\*\*/g, '').trim() // Remove leading dashes and ** markers
        )
        .filter((point: string) => point !== ''); // Remove empty points

      const updatedDescription = bulletPoints
        .map((point) => `- ${point}`)
        .join('\n'); // Format as single-dash bullets

      handleArrayChange(
        index,
        'description',
        updatedDescription,
        'work_experience'
      );
    } catch (err) {
      console.error('Error generating bullet points:', err);
      alert('Failed to generate bullet points. Please try again.');
    } finally {
      setGenerating(false);
    }
  };

  const adjustTextareaHeights = () => {
    try {
      descriptionRefs.current.forEach((textarea) => {
        if (textarea) {
          textarea.style.height = 'auto'; // Reset height
          textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to content height
        }
      });
    } catch (error) {
      console.error('Error adjusting textarea heights:', error);
    }
  };

  useLayoutEffect(() => {
    adjustTextareaHeights(); // Ensure immediate adjustment after DOM updates
  }, [formData.work_experience]);

  useEffect(() => {
    // Ensure refs array matches length of work experience array
    descriptionRefs.current = Array(formData?.work_experience?.length)
      .fill(null)
      .map((_, i) => descriptionRefs.current[i] || null);
  }, [formData.work_experience]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/user/getuser');
        setProfile(response.data);
        setFormData(response.data);
      } catch (err) {
        setError(`Failed to load profile information. ${err}`);
      } finally {
        setLoading(false);
        adjustTextareaHeights(); // Adjust directly after data is set
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleArrayChange = (
    index: number,
    field:
      | keyof WorkExperience
      | keyof Education
      | keyof Certification
      | keyof Skill,
    value: string,
    section: 'work_experience' | 'education' | 'certifications' | 'skills'
  ) => {
    const updatedArray = formData[section]?.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    ) as WorkExperience[] | Education[] | Certification[] | Skill[];
    setFormData({ ...formData, [section]: updatedArray });
  };

  const handleAddItem = (
    section: 'work_experience' | 'education' | 'certifications' | 'skills'
  ) => {
    let newItem;

    switch (section) {
      case 'work_experience':
        newItem = {
          jobTitle: '',
          company: '',
          start: '',
          end: '',
          description: '',
        };
        break;
      case 'education':
        newItem = {
          school: '',
          degree: '',
          field: '',
          startYear: '',
          endYear: '',
        };
        break;
      case 'certifications':
        newItem = {
          name: '',
          issuingOrganization: '',
          issueDate: '',
          expirationDate: '',
        };
        break;
      case 'skills':
        newItem = '';
        break;
      default:
        throw new Error(`Unsupported section: ${section}`);
    }

    setFormData({
      ...formData,
      [section]: [...(formData[section] || []), newItem],
    });
  };

  const handleRemoveItem = (
    section: 'work_experience' | 'education',
    index: number
  ) => {
    const updatedArray =
      section === 'work_experience'
        ? (formData[section] as WorkExperience[]).filter((_, i) => i !== index)
        : (formData[section] as Education[]).filter((_, i) => i !== index);

    setFormData({ ...formData, [section]: updatedArray });
  };

  const handleSave = async () => {
    try {
      const response = await api.post('/user/update', formData);
      setProfile(response.data);
      setEditMode(false);
    } catch (err) {
      console.error('Error saving profile:', err);
      setError('Failed to save profile information.');
    }
  };

  if (loading) return <div>Loading profile...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white mx-2 my-4 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-blue-500">
        Profile Information
      </h2>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <h3>
            <strong>Email:</strong>
          </h3>
          <p className="bg-gray-400 rounded-lg py-2 px-4 border-2 border-gray-300">
            {profile?.email}
          </p>
        </div>
        <div>
          <h3>
            <strong>Name:</strong>
          </h3>
          {editMode ? (
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              className="w-full px-2 py-1 rounded-lg border-2 border-gray-300"
            />
          ) : (
            <p className="border-2 border-gray-300 rounded-lg w-full px-4 py-2">
              {profile?.name || 'Not provided'}
            </p>
          )}
        </div>

        <div>
          <h3>
            <strong>Address:</strong>
          </h3>
          {editMode ? (
            <div className="space-y-2">
              <input
                type="text"
                name="street_address"
                value={formData.street_address || ''}
                onChange={handleChange}
                placeholder="Street Address"
                className="w-full px-2 py-1 border rounded-md"
              />
              <input
                type="text"
                name="city"
                value={formData.city || ''}
                onChange={handleChange}
                placeholder="City"
                className="w-full px-2 py-1 border rounded-md"
              />
              <input
                type="text"
                name="state"
                value={formData.state || ''}
                onChange={handleChange}
                placeholder="State"
                className="w-full px-2 py-1 border rounded-md"
              />
              <input
                type="text"
                name="zip"
                value={formData.zip || ''}
                onChange={handleChange}
                placeholder="ZIP Code"
                className="w-full px-2 py-1 border rounded-md"
              />
            </div>
          ) : (
            <p className="border-2 border-gray-300 rounded-lg w-full px-4 py-2 min-h-[2.6rem]">
              {profile?.street_address} {profile?.city} {profile?.state}
              {profile?.zip}
            </p>
          )}
        </div>

        <div>
          <h3>
            <strong>Contact:</strong>
          </h3>
          {editMode ? (
            <div className="grid grid-cols-2 gap-6">
              {/* Phone */}
              <div>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone || ''}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  className="w-full px-2 py-1 border rounded-md"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="linkedin"
                  value={formData.linkedin || ''}
                  onChange={handleChange}
                  placeholder="LinkedIn URL"
                  className="w-full px-2 py-1 border rounded-md"
                />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-6">
              {/* Phone */}
              <div>
                <p className="border-2 border-gray-300 rounded-lg px-4 py-2">
                  {profile?.phone || 'Phone Number'}
                </p>
              </div>
              {/* LinkedIn */}
              <div>
                <p className="border-2 border-gray-300 rounded-lg px-4 py-2">
                  {profile?.linkedin || 'Linkedin URL'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mb-6">
        <strong>Summary:</strong>
        {editMode ? (
          <textarea
            name="summary"
            value={formData.summary || ''}
            onChange={handleChange}
            className="w-full px-2 py-1 border rounded-md"
          />
        ) : (
          <p>{profile?.summary || 'Not provided'}</p>
        )}
      </div>

      <div className="grid grid-cols-3 gap-6">
        {/* Work Experience */}
        <div>
          <h3 className="text-lg font-bold mb-4 text-blue-500">
            Work Experience
          </h3>
          {formData.work_experience?.map((exp, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="Job Title"
                    value={exp.jobTitle}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'jobTitle',
                        e.target.value,
                        'work_experience'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <div>
                    <input
                      type="text"
                      placeholder="Company"
                      value={exp.company}
                      onChange={(e) =>
                        handleArrayChange(
                          index,
                          'company',
                          e.target.value,
                          'work_experience'
                        )
                      }
                      className="w-full px-2 py-1 mb-2 border rounded-md"
                    />
                    <input
                      type="date"
                      placeholder="Start Date"
                      value={exp.start}
                      onChange={(e) =>
                        handleArrayChange(
                          index,
                          'start',
                          e.target.value,
                          'work_experience'
                        )
                      }
                      className="w-full px-2 py-1 mb-2 border rounded-md"
                    />
                    <input
                      type="date"
                      placeholder="End Date"
                      value={exp.end}
                      onChange={(e) =>
                        handleArrayChange(
                          index,
                          'end',
                          e.target.value,
                          'work_experience'
                        )
                      }
                      className="w-full px-2 py-1 mb-2 border rounded-md"
                    />
                  </div>
                  <textarea
                    ref={(el) => (descriptionRefs.current[index] = el)} // Attach ref
                    placeholder="Description"
                    value={exp.description}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      target.style.height = 'auto'; // Reset height
                      target.style.height = `${target.scrollHeight}px`; // Adjust height
                      handleArrayChange(
                        index,
                        'description',
                        target.value,
                        'work_experience'
                      );
                    }}
                    className="w-full px-2 py-1 border rounded-md"
                  />
                  <button
                    onClick={() => generateBulletPoints(index)}
                    disabled={generating}
                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md"
                  >
                    {generating ? 'Generating...' : 'Generate Bullet Points'}
                  </button>
                  <MinusCircleIcon
                    onClick={() => handleRemoveItem('work_experience', index)}
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{exp.jobTitle}</p>
                  <div className="flex justify-between">
                    <p>{exp.company}</p>

                    <p>
                      {exp.start && exp.start.includes('-')
                        ? exp.start.split('-')[1] +
                          '/' +
                          exp.start.split('-')[0]
                        : 'Start date missing'}
                      {' - '}
                      {exp.end
                        ? exp.end.includes('-')
                          ? exp.end.split('-')[1] + '/' + exp.end.split('-')[0]
                          : 'Invalid end date'
                        : 'Present'}
                    </p>
                  </div>
                  <p>{exp.description}</p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() => handleAddItem('work_experience')}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Work Experience
            </button>
          )}
        </div>

        {/* Education */}
        <div>
          <h3 className="text-lg font-bold mb-4 text-blue-500">Education</h3>
          {formData.education?.map((edu, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="School"
                    value={edu.school}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'school',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Degree"
                    value={edu.degree}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'degree',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="date"
                    placeholder="Start Date"
                    value={edu.start}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'start',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="date"
                    placeholder="End Date"
                    value={edu.end}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'end',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <textarea
                    placeholder="Field of Study"
                    value={edu.field}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'field',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() => handleRemoveItem('education', index)}
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{edu.degree}</p>
                  <div className="flex justify-between">
                    <p>{edu.school}</p>
                    <p>
                      {edu.start && edu.start.includes('-')
                        ? edu.start.split('-')[1] +
                          '/' +
                          edu.start.split('-')[0]
                        : 'Start date missing'}
                      {' - '}
                      {edu.end
                        ? edu.end.includes('-')
                          ? edu.end.split('-')[1] + '/' + edu.end.split('-')[0]
                          : 'Invalid end date'
                        : 'Present'}
                    </p>
                  </div>
                  <p>{edu.field}</p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() => handleAddItem('education')}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Education
            </button>
          )}
        </div>

        {/* Skills */}
        <div>
          <h3 className="text-lg font-bold mb-4 text-blue-500">Skills</h3>
          {formData.skills?.map((skill, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="Skill Name"
                    value={skill.name}
                    onChange={(e) =>
                      handleArrayChange(index, 'name', e.target.value, 'skills')
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Proficiency (e.g., Beginner, Intermediate, Expert)"
                    value={skill.proficiency}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'proficiency',
                        e.target.value,
                        'skills'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() =>
                      setFormData({
                        ...formData,
                        skills: formData.skills?.filter((_, i) => i !== index),
                      })
                    }
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{skill.name}</p>
                  <p>{skill.proficiency}</p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() =>
                setFormData({
                  ...formData,
                  skills: [
                    ...(formData.skills || []),
                    { name: '', proficiency: '' },
                  ],
                })
              }
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Skill
            </button>
          )}
          {/* Certifications */}
          <h3 className="text-lg font-bold mb-4 text-blue-500 mt-12">
            Certifications
          </h3>
          {formData.certifications?.map((cert, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="Certification Name"
                    value={cert.name}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'name',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Issuing Organization"
                    value={cert.issuingOrganization}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'issuingOrganization',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="date"
                    placeholder="Issue Date"
                    value={cert.issueDate}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'issueDate',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="date"
                    placeholder="Expiration Date"
                    value={cert.expirationDate}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'expirationDate',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() =>
                      setFormData({
                        ...formData,
                        certifications: formData.certifications?.filter(
                          (_, i) => i !== index
                        ),
                      })
                    }
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{cert.name}</p>
                  <p>{cert.issuingOrganization}</p>
                  <p>
                    {cert.issueDate} - {cert.expirationDate || 'Present'}
                  </p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() => handleAddItem('certifications')}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Certification
            </button>
          )}
        </div>
      </div>

      <div className="mt-6 text-right">
        <button
          onClick={editMode ? handleSave : () => setEditMode(true)}
          className="px-6 py-2 bg-blue-500 text-white rounded-md"
        >
          {editMode ? 'Save' : 'Edit'}
        </button>
      </div>
    </div>
  );
};

export default Profile;
