import React from 'react';
import { useNavigate } from 'react-router-dom';
import NumberIcon from './NumberIcon';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full p-4">
      <div className="h-auto md:h-[80vh] flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-8 mt-8">
          <div className="z-10 p-5 mt-20 md:mt-0 md:p-8">
            <h1 className="text-3xl md:text-4xl font-bold text-blue-500">
              Simplify Your Job Search with Talium
            </h1>
            <p className="text-xl md:text-2xl mt-4 text-slate-600">
              Talium gathers job listings from across the web, so you can spend
              less time searching and more time finding the right job. Start
              your journey towards a fulfilling career, all in one convenient
              place.
            </p>
            <button
              onClick={() => navigate('/profile')}
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              aria-label="Get Started"
            >
              Get Started
            </button>
          </div>
          <div
            className="transition-all duration-500 block rounded-lg aspect-video bg-center bg-no-repeat bg-contain"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/LandingIntroPic.png)`,
            }}
            aria-label="Landing Intro Image"
          />
        </div>
      </div>

      <div className="bg-white rounded-xl">
        <section className="flex flex-col md:flex-row items-center justify-center p-6 my-8">
          <NumberIcon number={1} position="left" />
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">ABOUT US</div>
            <h2 className="text-3xl mb-4 text-black">Bringing Jobs to You</h2>
            <p className="text-lg text-slate-600">
              At Talium, we simplify the job hunt by consolidating listings from
              various platforms into one easy-to-navigate site. We&apos;re here
              to streamline your search, helping you access countless job
              opportunities all in one place – making it easier to find your
              next career move.
            </p>
          </div>
        </section>

        <hr className="border-b-2 border-slate-200 w-[80%] my-8 mx-auto" />

        {/* Feature 2: Discover Opportunities */}
        <section className="flex flex-col md:flex-row items-center justify-center p-6 my-8">
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              DISCOVER OPPORTUNITIES
            </div>
            <h2 className="text-3xl mb-4 text-black">
              Comprehensive Job Listings
            </h2>
            <p className="text-lg text-slate-600">
              Talium connects you to a vast range of job opportunities from
              trusted sources. Whether you&apos;re looking for a new challenge
              or starting fresh, our intuitive platform brings the latest jobs
              to your fingertips, all in real-time.
            </p>
          </div>
          <NumberIcon number={2} position="right" />
        </section>

        <hr className="border-b-2 border-slate-200 w-[80%] my-8 mx-auto" />

        {/* Feature 3: Customized Cover Letters */}
        <section className="flex flex-col md:flex-row items-center justify-center p-6 my-8">
          <NumberIcon number={3} position="left" />
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              CUSTOMIZED COVER LETTERS
            </div>
            <h2 className="text-3xl mb-4 text-black">
              Personalized Cover Letters
            </h2>
            <p className="text-lg text-slate-600">
              Stand out from the competition with tailored cover letters crafted
              to highlight your unique skills and experiences for each job
              application. Talium&apos;s tool ensures your cover letter aligns
              perfectly with the job description, making a memorable impression
              on potential employers.
            </p>
          </div>
        </section>

        <hr className="border-b-2 border-slate-200 w-[80%] my-8 mx-auto" />

        {/* Feature 4: Tailored Resumes */}
        <section className="flex flex-col md:flex-row items-center justify-center p-6 my-8">
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              TAILORED RESUMES
            </div>
            <h2 className="text-3xl mb-4 text-black">Customized Resumes</h2>
            <p className="text-lg text-slate-600">
              Enhance your job applications with resumes tailored to each
              position you apply for. Talium&apos;s resume builder adjusts your
              resume&apos;s format and content to match the specific
              requirements and keywords of the job posting, increasing your
              chances of landing an interview.
            </p>
          </div>
          <NumberIcon number={4} position="right" />
        </section>
      </div>

      <div>
        <section className="flex flex-col items-center justify-center px-4 my-8 bg-white rounded-lg p-6">
          <div className="text-sm text-blue-500 font-bold mb-2">
            GET STARTED WITH TALIUM
          </div>
          <h2 className="text-3xl mb-4 text-center text-black">
            Find Your Dream Job in Just a Few Clicks
          </h2>
          <div
            className="w-full md:w-1/2 mb-4 rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/Image3.png)`,
            }}
            aria-label="Get Started Image"
          />
          <button
            onClick={() => navigate('/profile')}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            aria-label="Get Started"
          >
            Start Building Your Profile
          </button>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
