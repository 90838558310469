import React, { useRef, useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import api from '../../services/api';
import { useAuth } from '../../middleware/AuthContext';
import SignIn from '../SignIn/SignIn';

const CoverLetter: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(!isAuthenticated); // Open sign-in modal if not authenticated
  const [jobDescription, setJobDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [initialCoverLetter, setInitialCoverLetter] = useState<string>('');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    }
  }, [isAuthenticated]);

  const handleGenerateCoverLetter = async () => {
    setIsGenerating(true);
    try {
      const { data: userProfile } = await api.get('/user/getuser');
      const response = await api.post('/openai/coverletter', {
        jobDescription,
        userProfile,
      });
      const generatedLetter =
        response.data.coverLetterParagraphs?.join('\n\n') || '';
      setInitialCoverLetter(generatedLetter);

      if (contentRef.current) {
        contentRef.current.textContent = generatedLetter;
      }
    } catch (error) {
      console.error('Error generating cover letter:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleExportToPDF = () => {
    const content = document.getElementById('coverLetterContent');
    if (content) {
      html2canvas(content).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 10, 10, 180, 0);
        pdf.save('cover_letter.pdf');
      });
    }
  };

  useEffect(() => {
    if (contentRef.current && initialCoverLetter) {
      contentRef.current.textContent = initialCoverLetter;
    }
  }, [initialCoverLetter]);

  // Show sign-in modal if not authenticated
  if (!isAuthenticated) {
    return isModalOpen ? (
      <SignIn onClose={() => setIsModalOpen(false)} />
    ) : null;
  }

  return (
    <div className="container mx-auto p-4 sm:p-6">
      <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">
        Cover Letter Generator
      </h1>

      <textarea
        className="w-full p-2 sm:p-4 mb-4 border border-gray-300 rounded text-sm sm:text-base"
        placeholder="Paste the job description here..."
        value={jobDescription}
        onChange={(e) => setJobDescription(e.target.value)}
      />

      <button
        className="w-full sm:w-auto bg-blue-500 text-white py-2 px-4 rounded mb-4 text-sm sm:text-base"
        onClick={handleGenerateCoverLetter}
        disabled={isGenerating}
      >
        {isGenerating ? 'Generating...' : 'Generate Cover Letter'}
      </button>

      <div
        id="coverLetterContent"
        ref={contentRef}
        className="rounded-lg mb-4 bg-white text-sm sm:text-base"
        contentEditable
        suppressContentEditableWarning
        style={{
          whiteSpace: 'pre-wrap',
          fontFamily: 'Arial, sans-serif',
          fontSize: '24px',
          lineHeight: '1.7',
          minHeight: '300px',
          padding: '16px',
          border: '1px solid #ccc',
        }}
      ></div>

      {initialCoverLetter && (
        <div className="flex flex-col sm:flex-row gap-4">
          <button
            className="w-full sm:w-auto bg-green-500 text-white py-2 px-4 rounded text-sm sm:text-base"
            onClick={handleExportToPDF}
          >
            Export to PDF
          </button>
        </div>
      )}
    </div>
  );
};

export default CoverLetter;
