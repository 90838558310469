import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'https://api.talium.io/api';
interface SignInProps {
  onClose: () => void;
}

const PassportButtons = () => {
  const googleSignIn = () => {
    window.location.href = `${BACKEND_URL}/auth/google`;
  };

  const microsoftSignIn = () => {
    window.location.href = `${BACKEND_URL}/auth/microsoft`;
  };

  return (
    <div className="flex flex-col space-y-4">
      <button
        onClick={googleSignIn}
        className="flex items-center justify-center space-x-2 border border-gray-300 rounded-lg p-2 hover:bg-gray-100"
      >
        <img
          src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
          alt="Google G logo"
          className="w-6 h-6"
        />
        <span>Sign in with Google</span>
      </button>

      <button
        onClick={microsoftSignIn}
        className="flex items-center justify-center space-x-2 border border-gray-300 rounded-lg p-2 hover:bg-gray-100"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1200px-Microsoft_logo.svg.png"
          alt="Microsoft logo"
          className="w-6 h-6"
        />
        <span>Sign in with Microsoft</span>
      </button>
    </div>
  );
};

const SignIn: React.FC<SignInProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose(); // Close the modal
    navigate('/'); // Redirect to the homepage
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Sign In</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-black"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>

        <div className="modal-body">
          <PassportButtons />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
