import React from 'react';
import { Link } from 'react-router-dom';

// sample blog posts data
const blogPosts = [
  {
    id: 1,
    title: 'About Talium',
    date: 'August 25, 2023',
    excerpt:
      'Learn more about Talium, our mission, and how we empower businesses with innovative solutions.',
    imageUrl: '/images/Image1.png',
    link: '/blog/post1',
  },
  {
    id: 2,
    title: 'Our Purpose',
    date: 'September 10, 2023',
    excerpt:
      'Discover the driving force behind Talium and our commitment to excellence and innovation.',
    imageUrl: '/images/Image2.png',
    link: '/blog/post2',
  },
];

const Blog = () => {
  const reversedPosts = [...blogPosts].reverse();
  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto px-4 py-8">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {reversedPosts.map((post) => (
            <div
              key={post.id}
              className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                  {post.title}
                </h3>
                <p className="text-gray-600 text-sm mb-4">{post.date}</p>
                <p className="text-gray-700 mb-4">{post.excerpt}</p>
                <Link
                  to={post.link}
                  className="text-blue-600 hover:text-blue-800 font-semibold"
                >
                  Read More &rarr;
                </Link>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Blog;
