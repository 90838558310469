import React, { useEffect, useRef, useState } from 'react';
import { UserProfile } from '../../../models/profile';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer';

interface Format1Props {
  userProfile: UserProfile;
}

const tailwindColors = {
  blue400: '#60A5FA',
  gray700: '#374151',
  gray500: '#6B7280',
  white: '#FFFFFF',
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 24,
    fontSize: 12,
    fontFamily: 'Helvetica',
    color: tailwindColors.gray700, // Match text-gray-700 for body text
    backgroundColor: tailwindColors.white, // match bg-white
    borderRadius: 4, // mimic rounded-md (slight rounding)
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: tailwindColors.blue400, // match text-blue-400
    marginBottom: 4,
    borderBottomWidth: 2,
    borderBottomColor: tailwindColors.blue400,
    borderBottomStyle: 'solid',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textItalic: {
    fontStyle: 'italic',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  dateContainer: {
    width: 120,
    textAlign: 'right',
    flexShrink: 0,
  },
});

const Format1: React.FC<Format1Props> = ({ userProfile: initialProfile }) => {
  const [userProfile, setUserProfile] = useState<UserProfile>(initialProfile);
  const descriptionRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  useEffect(() => {
    // Adjust textarea heights if needed
    descriptionRefs.current.forEach((textarea) => {
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    });
  }, [userProfile]);

  const handleInputChange = (field: string, value: string) => {
    setUserProfile({ ...userProfile, [field]: value });
  };

  const handleNestedInputChange = <
    T extends 'work_experience' | 'education' | 'skills' | 'certifications',
  >(
    section: T,
    index: number,
    field: keyof NonNullable<UserProfile[T]>[number],
    value: string
  ) => {
    const sectionData = userProfile[section] as unknown;
    if (Array.isArray(sectionData)) {
      const updatedSection = [...sectionData];
      updatedSection[index] = { ...updatedSection[index], [field]: value };
      setUserProfile({ ...userProfile, [section]: updatedSection });
    } else {
      console.error(`Expected ${section} to be an array but got`, sectionData);
    }
  };

  const getCityState = () => {
    const city = userProfile.city || '';
    const state = userProfile.state || '';
    return `${city}${city && state ? ', ' : ''}${state}`;
  };

  const startToEndDate = (start: string, end: string) => {
    if (start && end) {
      return `${start.split('-')[1]}/${start.split('-')[0]} - ${end.split('-')[1]}/${end.split('-')[0]}`;
    } else if (start) {
      return `${start.split('-')[1]}/${start.split('-')[0]} - Present`;
    } else if (end) {
      return `Until ${end.split('-')[1]}/${end.split('-')[0]}`;
    } else {
      return '';
    }
  };

  const MyDocument = ({ profile }: { profile: UserProfile }) => (
    <Document>
      <Page style={styles.page} size="A4">
        {/* Header */}
        <View style={styles.section}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 8,
              color: tailwindColors.gray700, // ensure header text matches body
            }}
          >
            {profile.name}
          </Text>
          <Text style={{ textAlign: 'center', color: tailwindColors.gray700 }}>
            {getCityState()} | {profile.email} | {profile.linkedin} |{' '}
            {profile.phone}
          </Text>
        </View>

        {/* Education */}
        {profile.education && profile.education.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.heading}>Education</Text>
            {profile.education.map((edu, i) => (
              <View key={i} style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.textBold}>{edu.school}</Text>
                  <Text>
                    {(edu.degree || '') + (edu.field ? ` in ${edu.field}` : '')}
                  </Text>
                </View>
                <Text style={styles.dateContainer}>
                  {startToEndDate(edu.start || '', edu.end || '')}
                </Text>
              </View>
            ))}
          </View>
        )}

        {/* Work Experience */}
        {profile.work_experience && profile.work_experience.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.heading}>Work Experience</Text>
            {profile.work_experience.map((work, i) => (
              <View key={i} style={{ marginBottom: 10 }}>
                {/* Company and Date in one line */}
                <View style={styles.row}>
                  <Text style={styles.textBold}>{work.company}</Text>
                  <Text style={styles.dateContainer}>
                    {startToEndDate(work.start || '', work.end || '')}
                  </Text>
                </View>
                {/* Job title and description spanning full width below */}
                <Text style={styles.textItalic}>{work.jobTitle}</Text>
                <Text>{work.description}</Text>
              </View>
            ))}
          </View>
        )}

        {/* Certifications */}
        {profile.certifications && profile.certifications.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.heading}>Certifications</Text>
            {profile.certifications.map((cert, i) => (
              <View key={i} style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.textBold}>
                    {cert.issuingOrganization}
                  </Text>
                  <Text>{cert.name}</Text>
                </View>
                <Text style={styles.dateContainer}>
                  {startToEndDate(
                    cert.issueDate || '',
                    cert.expirationDate || ''
                  )}
                </Text>
              </View>
            ))}
          </View>
        )}

        {/* Skills */}
        {profile.skills && profile.skills.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.heading}>Skills</Text>
            {profile.skills.map((skill, i) => (
              <Text key={i}>
                {skill.name} - {skill.proficiency}
              </Text>
            ))}
          </View>
        )}
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="bg-white rounded-md shadow-md max-w-3xl mx-auto px-6 py-4">
        {/* Editable Fields for User Profile */}
        <div className="text-center mb-4">
          <input
            type="text"
            className="text-2xl font-bold my-2 w-full text-center"
            placeholder="Your Name"
            value={userProfile.name || ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          <div className="tag-line text-sm mx-auto flex justify-between items-center">
            <input
              type="text"
              className="p-1 rounded-md text-center"
              placeholder="City, State"
              value={getCityState()}
              onChange={(e) => {
                const [city, state] = e.target.value
                  .split(',')
                  .map((s) => s.trim());
                handleInputChange('city', city || '');
                handleInputChange('state', state || '');
              }}
            />
            <span className="text-gray-500">|</span>
            <input
              type="text"
              className="p-1 rounded-md text-center"
              placeholder="email@example.com"
              value={userProfile.email || ''}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
            <span className="text-gray-500">|</span>
            <input
              type="text"
              className="p-1 rounded-md text-center"
              placeholder="LinkedIn URL"
              value={userProfile.linkedin || ''}
              onChange={(e) => handleInputChange('linkedin', e.target.value)}
            />
            <span className="text-gray-500">|</span>
            <input
              type="text"
              className="p-1 rounded-md text-left"
              placeholder="(123) 456-7890"
              value={userProfile.phone || ''}
              onChange={(e) => handleInputChange('phone', e.target.value)}
            />
          </div>
        </div>

        {/* Education Section */}
        <div className="mt-2">
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Education
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.education?.map((edu, index) => (
              <div key={index}>
                <div>
                  <input
                    type="text"
                    className="w-full my-1 rounded-md"
                    placeholder="Enter school/university name"
                    value={edu.school || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'education',
                        index,
                        'school',
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md"
                    placeholder="Enter degree and field of study (e.g., 'B.Sc in Computer Science')"
                    value={`${edu.degree || ''}${
                      edu.field ? ` in ${edu.field}` : ''
                    }`}
                    onChange={(e) => {
                      const [degree, field] = e.target.value.split(' in ');
                      handleNestedInputChange(
                        'education',
                        index,
                        'degree',
                        degree
                      );
                      handleNestedInputChange(
                        'education',
                        index,
                        'field',
                        field
                      );
                    }}
                  />
                  <input
                    type="text"
                    className="w-1/4 rounded-md text-right pr-2"
                    placeholder="Graduation Year"
                    value={startToEndDate(edu.start || '', edu.end || '')}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            )) || (
              <p className="text-gray-500">No education details available.</p>
            )}
          </div>
        </div>

        {/* Work Experience Section */}
        <div>
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Work Experience
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.work_experience?.map((work, index) => (
              <div key={index} className="mb-2">
                <div>
                  <input
                    type="text"
                    className="w-full my-1 rounded-md font-bold"
                    placeholder="Enter company name"
                    value={work.company || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'work_experience',
                        index,
                        'company',
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md italic"
                    placeholder="Enter job title"
                    value={work.jobTitle || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'work_experience',
                        index,
                        'jobTitle',
                        e.target.value
                      )
                    }
                  />
                  <input
                    type="text"
                    className="w-1/4 rounded-md text-right pr-2"
                    placeholder="Duration (e.g., 'Jan 2020 - Dec 2022')"
                    value={startToEndDate(work.start || '', work.end || '')}
                    readOnly
                    disabled
                  />
                </div>
                <textarea
                  ref={(el) => (descriptionRefs.current[index] = el)}
                  className="w-full py-1 rounded-md mt-1 resize-none"
                  placeholder="Enter a brief description of your responsibilities and achievements"
                  value={work.description || ''}
                  onInput={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = 'auto';
                    target.style.height = `${target.scrollHeight}px`;
                    handleNestedInputChange(
                      'work_experience',
                      index,
                      'description',
                      target.value
                    );
                  }}
                  style={{
                    overflow: 'hidden',
                    height: 'auto',
                    minHeight: '3rem',
                  }}
                ></textarea>
              </div>
            )) || (
              <p className="text-gray-500">
                No work experience details available.
              </p>
            )}
          </div>
        </div>

        {/* Certifications Section */}
        <div className="mb-2">
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Certifications
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.certifications?.map((cert, index) => (
              <div key={index} className="mb-4">
                <div className="mb-2">
                  <input
                    type="text"
                    className="w-full rounded-md"
                    placeholder="Enter issuing organization"
                    value={cert.issuingOrganization || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'certifications',
                        index,
                        'issuingOrganization',
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="flex justify-between items-center space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md text-left"
                    placeholder="Enter certification name"
                    value={cert.name || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'certifications',
                        index,
                        'name',
                        e.target.value
                      )
                    }
                  />
                  <input
                    type="text"
                    className="w-1/3 rounded-md text-right"
                    placeholder="Enter dates (e.g., 2023-01 - 2023-12)"
                    value={startToEndDate(
                      cert.issueDate || '',
                      cert.expirationDate || ''
                    )}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            )) || <p className="text-gray-500">No certifications available.</p>}
          </div>
        </div>

        {/* Skills Section */}
        <div className="mb-2">
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Skills
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.skills?.map((skill, index) => (
              <div key={index}>
                <div className="flex justify-between space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md"
                    placeholder="Enter skill"
                    value={skill.name || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'skills',
                        index,
                        'name',
                        e.target.value
                      )
                    }
                  />
                  <input
                    type="text"
                    className="w-1/4 rounded-md text-right pr-2"
                    placeholder="Proficiency (e.g., Beginner, Intermediate, Expert)"
                    value={skill.proficiency || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'skills',
                        index,
                        'proficiency',
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            )) || <p className="text-gray-500">No skills available.</p>}
          </div>
        </div>
      </div>

      <PDFDownloadLink
        document={<MyDocument profile={userProfile} />}
        fileName="resume.pdf"
      >
        <button className="mt-4 bg-green-500 text-white py-2 px-6 rounded">
          Download Resume
        </button>
      </PDFDownloadLink>
    </div>
  );
};

export default Format1;
