import React from 'react';

const Post2 = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <img
        src="/images/Image2.png"
        alt="Our Purpose"
        className="w-full h-64 object-cover rounded-lg shadow-md"
        loading="lazy"
      />

      <div className="mt-6">
        <h1 className="text-4xl font-bold text-gray-800">Our Purpose</h1>
        <div className="flex items-center mt-2">
          <img
            src="https://randomuser.me/api/portraits/women/44.jpg"
            alt="Author Avatar"
            className="w-10 h-10 rounded-full"
          />
          <div className="ml-3">
            <p className="text-gray-700 font-semibold">Jane Smith</p>
            <p className="text-gray-500 text-sm">
              Published on September 10, 2023
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 text-gray-700 space-y-4">
        <p>
          At Talium, our purpose is to revolutionize the job application process
          by consolidating job listings from across the web and empowering users
          to create tailored resumes and cover letters effortlessly. We
          understand the challenges faced by job seekers in navigating numerous
          job boards and the tedious task of customizing application materials
          for each position. Our mission is to streamline this process, saving
          valuable time and increasing the chances of securing meaningful
          employment.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Simplifying Job Searches
        </h2>
        <p>
          The modern job market is vast and fragmented, with countless
          opportunities scattered across various platforms. Talium aggregates
          these listings into a single, user-friendly interface, allowing you to
          browse and discover jobs that align with your career aspirations
          without the hassle of switching between multiple websites.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Personalized Application Materials
        </h2>
        <p>
          Crafting a unique resume and cover letter for each job application can
          be overwhelming and time-consuming. Talium&apos;s intuitive tools
          enable you to generate customized documents tailored to the specific
          requirements of each posting. Our platform analyzes job descriptions
          and suggests relevant skills and experiences to highlight, ensuring
          your application stands out to potential employers.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Centralized Platform for Enhanced Efficiency
        </h2>
        <p>
          By bringing together job listings from various boards and providing
          advanced tools for application customization, Talium offers a
          centralized solution that enhances your job search efficiency. Whether
          you&apos;re actively seeking new opportunities or exploring potential
          career paths, Talium equips you with the resources needed to navigate
          the job market confidently and effectively.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Combating Rejections with Smart Applications
        </h2>
        <p>
          Frequent rejections can be disheartening and may lead to burnout
          during the job search process. Talium addresses this issue by enabling
          you to apply smarter, not harder. By ensuring each application is
          tailored and optimized, we help increase your visibility to employers,
          thereby improving your chances of receiving interview invitations and
          job offers.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Empowering Your Career Journey
        </h2>
        <p>
          Our commitment extends beyond providing tools; we aim to support you
          throughout your career journey. Talium offers insights and analytics
          to help you understand application trends, track your progress, and
          make informed decisions about your job search strategies. Our goal is
          to empower you with the knowledge and resources needed to achieve your
          professional goals.
        </p>
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-semibold text-gray-800">Tags:</h3>
        <div className="flex space-x-2 mt-2">
          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
            Job Search
          </span>
          <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            Resume Builder
          </span>
          <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">
            Career Tools
          </span>
        </div>
      </div>
    </div>
  );
};

export default Post2;
