import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (authStatus: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Check if an auth token exists in cookies
        const response = await api.get('/auth/status', {
          withCredentials: true, // Ensure cookies are sent with the request
        });
        setIsAuthenticated(
          response.status === 200 && response.data.isAuthenticated
        );
        if (response.status === 200 && response.data.isAuthenticated) {
          localStorage.setItem('isAuthenticated', 'true'); // Set persistence in localStorage
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.error('Error checking auth status:', error);
      }
    };

    // Check `localStorage` first to set initial state
    if (localStorage.getItem('isAuthenticated') === 'true') {
      setIsAuthenticated(true);
    } else {
      checkAuthStatus();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
