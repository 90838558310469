import React from 'react';

const Post1 = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <img
        src="/images/Image1.png"
        alt="About Talium"
        className="w-full h-64 object-cover rounded-lg shadow-md"
        loading="lazy"
      />

      <div className="mt-6">
        <h1 className="text-4xl font-bold text-gray-800">About Talium</h1>
        <div className="flex items-center mt-2">
          <img
            src="https://randomuser.me/api/portraits/men/32.jpg"
            alt="Author Avatar"
            className="w-10 h-10 rounded-full"
          />
          <div className="ml-3">
            <p className="text-gray-700 font-semibold">John Doe</p>
            <p className="text-gray-500 text-sm">
              Published on August 25, 2023
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 text-gray-700 space-y-4">
        <p>
          Welcome to Talium! We are dedicated to revolutionizing the job
          application process by sourcing listings from across the web and
          providing tools that empower job seekers to create tailored resumes
          and cover letters for each position they find.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Our Mission
        </h2>
        <p>
          At Talium, our mission is to combat the overwhelming time and effort
          that job seekers expend applying for positions, often only to face
          numerous rejections. We understand that manually creating a unique
          resume and cover letter for each job posting is not only
          time-consuming but also inefficient.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          What We Offer
        </h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>Aggregated Job Listings:</strong> We consolidate job
            postings from various job boards into one centralized platform,
            making it easier for you to find opportunities that match your
            skills and interests.
          </li>
          <li>
            <strong>Tailored Resume Builder:</strong> Our intuitive resume
            builder allows you to create customized resumes that highlight the
            most relevant skills and experiences for each job you apply to.
          </li>
          <li>
            <strong>Cover Letter Generator:</strong> Craft compelling cover
            letters effortlessly with our generator, designed to align with the
            specific requirements of each job posting.
          </li>
          <li>
            <strong>Time-Saving Tools:</strong> Streamline your job application
            process with features that reduce the time spent on repetitive
            tasks, enabling you to focus on landing your dream job.
          </li>
          <li>
            <strong>Analytics and Insights:</strong> Gain valuable insights into
            your job application performance, helping you optimize your
            strategies for better results.
          </li>
        </ul>
        <p>
          By leveraging Talium, you can significantly reduce the time spent on
          job applications and increase your chances of securing interviews by
          presenting personalized and targeted application materials.
        </p>
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-semibold text-gray-800">Tags:</h3>
        <div className="flex space-x-2 mt-2">
          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
            Job Search
          </span>
          <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            Resume Builder
          </span>
          <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">
            Career Tools
          </span>
        </div>
      </div>
    </div>
  );
};

export default Post1;
