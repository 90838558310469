import React from 'react';

interface NumberIconProps {
  number: number;
  position: 'left' | 'right';
}

const NumberIcon: React.FC<NumberIconProps> = ({ number, position }) => (
  <div
    className={`${position === 'left' ? 'mr-6' : 'ml-6'} hidden md:flex items-center justify-center w-24 h-24 bg-blue-500 text-white rounded-full text-4xl font-bold hover:scale-110 hover:bg-blue-600 transition-transform duration-300`}
    aria-label={`Section ${number}`}
  >
    {number}
  </div>
);

export default NumberIcon;
