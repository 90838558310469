import React, { useState } from 'react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <footer className="bg-gray-800 text-white pt-8 pb-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="mb-6 md:mb-0 md:w-1/4">
            <h2 className="text-xl font-semibold mb-4">About Talium.io</h2>
            <p className="text-gray-400">
              Talium.io aggregates job listings from various third-party job
              boards. We strive to provide accurate and up-to-date job
              opportunities to help you advance your career.
            </p>
          </div>

          <div className="mb-6 md:mb-0 md:w-1/4">
            <h2 className="text-xl font-semibold mb-4">Quick Links</h2>
            <ul>
              <li className="mb-2">
                <a
                  href="/"
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Home
                </a>
              </li>
              {/* <li className="mb-2">
                <a
                  href="/jobs"
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Jobs
                </a>
              </li> */}
              <li className="mb-2">
                <a
                  href="/blog"
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Blog
                </a>
              </li>
              <li className="mb-2">
                <button
                  onClick={openModal}
                  className="text-gray-400 hover:text-white transition-colors duration-200 focus:outline-none"
                >
                  Disclaimer
                </button>
              </li>
            </ul>
          </div>

          <div className="mb-6 md:mb-0 md:w-1/4">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <p className="text-gray-400">
              1234 Give Me A Job Street
              <br />
              City, State, 56789
            </p>
            <p className="text-gray-400 mt-2">
              Email:{' '}
              <a href="mailto:info@talium.io" className="hover:text-white">
                info@talium.io
              </a>
            </p>
            <p className="text-gray-400">
              Phone:{' '}
              <a href="tel:+1234567890" className="hover:text-white">
                +1 (234) 567-890
              </a>
            </p>
          </div>

          <div className="md:w-1/4">
            <h2 className="text-xl font-semibold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Facebook"
              >
                <BriefcaseIcon className="w-5 h-5" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Twitter"
              >
                <BriefcaseIcon className="w-5 h-5" />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="LinkedIn"
              >
                <BriefcaseIcon className="w-5 h-5" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Instagram"
              >
                <BriefcaseIcon className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-4 text-center">
          <p className="text-gray-500">
            © 2024 Talium.io. All rights reserved.
          </p>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white py-2 px-6 rounded-lg shadow-lg w-11/12 max-w-md relative border-2 border-blue-500">
            <button
              onClick={closeModal}
              className="text-gray-500 hover:text-gray-700 absolute top-2 right-2 text-2xl font-bold"
              aria-label="Close Disclaimer Modal"
            >
              &times;
            </button>
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Disclaimer</h3>
              <p className="text-gray-700">
                Talium.io aggregates job listings from various third-party job
                boards. We do not own, endorse, or assume responsibility for the
                accuracy, availability, or legitimacy of these listings. Users
                are advised to verify details on the original job boards before
                applying. All trademarks and company logos are the property of
                their respective owners, and their use on this site is for
                informational purposes only.
              </p>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
